/* NAVIGATION DROPDOWN
================================*/
.navigation-dropdown {
  width: 100%;
  max-height: 0;
  margin: 0;
  overflow: hidden;
  list-style: none;
  position: static;
  display: block;
  z-index: 20001;
  background-color: #1b1c1d;

  @include transition(max-height 2s cubic-bezier(0, 1, 0, 1));

  &.is-visible {
	max-height: $dropdown-max-height;
	padding: 0;
	@include transition(max-height 5s ease-out);
  }

  @media (min-width: $landscape-width) {
	width: $dropdown-width;
	position: absolute;
	display: inline-block;
	opacity: 0;
	margin-top: 40px;
	box-shadow: $dropdown-box-shadow-landscape;
	border-radius: 5px;
	background-color: $dropdown-background-color;
	@include transition(opacity .3s linear, max-height 0s linear .15s, margin .3s);

	&.is-visible {
	  margin-top: 0;
	  opacity: 1;
	  overflow: visible;
	  padding: 5px 0;
	  @include transition(opacity .3s linear, max-height 0s linear, margin .3s);
	}
  }

  .navigation-dropdown {
	left: 100%;
  }

  .navigation-dropdown-left {
	right: 100%;
	left: auto;
  }
}

// Dropdown item
.navigation-dropdown-item {
  width: 100%;
  margin: 0;
  float: left;
  display: inline-block;

  &.has-submenu {
	position: relative;

	.navigation-dropdown {
	  top: 0;
	}

	& > .navigation-dropdown-link {

	  &:after {
		content: "\25E5";
		position: relative;
		font-size: 9px;
		margin-left: 10px;
		@include transform(rotate(45deg));
		transition: all .3s ease;
	  }
	}
  }
}

// Dropdown link
.navigation-dropdown-link {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  color: var(--white-color);
  border-bottom: solid 1px $dropdown-link-border-color;
  font-weight: 500;
  @include transition(border .3s, color .3s, background .3s);

  &:hover {
	color: var(--primary-accent-color) !important;
  }

  &:visited {
	color: $white;
  }

  .navigation-dropdown-item:hover > &,
  .navigation-dropdown-item:focus > &,
  .navigation-dropdown-item.is-active > & {
	text-decoration: none !important;
  }

  & {
	padding: $dropdown-link-padding;
  }
  & + ul .navigation-dropdown-link {
	padding-left: $dropdown-link-padding-level-2;
  }
  & + ul & + ul .navigation-dropdown-link {
	padding-left: $dropdown-link-padding-level-3;
  }
  & + ul & + ul & + ul .navigation-dropdown-link {
	padding-left: $dropdown-link-padding-level-4;
  }
  & + ul & + ul & + ul & + ul .navigation-dropdown-link {
	padding-left: $dropdown-link-padding-level-5;
  }
  & + ul & + ul & + ul & + ul & + ul .navigation-dropdown-link {
	padding-left: $dropdown-link-padding-level-6;
  }

  @media (min-width: $landscape-width) {
	padding-left: $dropdown-link-padding-landscape;
	border-bottom: none;
	font-size: $dropdown-link-font-size;
	color: $dropdown-link-font-color;

	&:visited {
	  color: $dropdown-link-font-color;
	}
  }

  // Submenu indicator
  > .submenu-indicator {
	right: 0;
	top: 0;
	position: absolute;

	.navigation-dropdown-item:hover > &:after,
	.navigation-dropdown-item:focus > &:after,
	.navigation-dropdown-item.is-active > &:after {
	  border-color: transparent transparent;
	}

	@media (min-width: $landscape-width) {
	  position: absolute;
	  top: 50%;
	  right: 10px;
	  @include transform(translateY(-50%));

	  &:after {
		@include transform(rotate(-45deg));
	  }

	  &.submenu-indicator-left:after {
		@include transform(rotate(135deg));
	  }
	}
  }
}