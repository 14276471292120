/* NAVIGATION BODY
================================*/
.navigation-body {
  width: $navigation-offcanvas-width;
  height: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #1b1c1d;
  z-index: 20000;
  @include transition(left .8s cubic-bezier(.1, .1, .1, .1), right .8s cubic-bezier(.1, .1, .1, .1));

  @media (min-width: $navigation-offcanvas-medium-large-breakpoint) {
	width: $navigation-offcanvas-medium-large-width;
  }

  @media (min-width: $landscape-width) {
	@include flex();
	@include flexAlignCenterVertically();
	width: 100%;
	position: relative;
	left: auto;
	overflow: visible;
	z-index: auto;
	background-color: transparent;
	@include transition(all 0s ease 0s);
  }

  &.offcanvas-right {
	left: auto;
	right: -100%;

	@media (min-width: $landscape-width) {
	  right: auto;
	}
  }

  &.is-visible {
	left: 0;
	@include transition(left .8s, right .8s);
  }

  &.offcanvas-right.is-visible {
	right: 0;
	left: auto;

	@media (min-width: $landscape-width) {
	  right: auto;
	}
  }

  &.is-invisible {
	left: -100%;
  }

  &.offcanvas-right.is-invisible {
	right: -100%;
  }

  &.scroll-momentum {
	-webkit-overflow-scrolling: touch;
  }

  @media (min-width: $landscape-width) {
	// Centered navigation
	.navigation-centered & {
	  @include flexAlignCenter();
	}

	// Justified navigation
	.navigation-justified & {
	  @include flexAlignBetween();
	}

	// Navigation with logo on top
	.navigation-logo-top & {
	  @include flexColumn();
	}
  }
}

// Navigation body header
.navigation-body-header {
  @include flex();
  @include flexAlignCenterVertically();
  @include flexAlignBetween();
  padding: $navigation-body-header-padding;
  border-bottom: solid 1px $navigation-body-header-border;

  @media (min-width: $landscape-width) {
	border: none;
	padding: 16px 0;

	.navigation-centered & {
	  padding: 0;
	}
	.navigation-logo-top & {
	  padding-top: 20px;
	}
  }
}

// Close button
.navigation-body-close-button {
  @include flex();
  @include flexAlignCenter();
  @include flexAlignCenterVertically();
  width: 30px;
  height: 30px;
  margin-left: auto;
  padding-bottom: 2px;
  line-height: initial;
  text-align: center;
  font-size: $navigation-body-close-button-font-size;
  color: $navigation-body-close-button-color;
  cursor: pointer;

  @media (min-width: $landscape-width) {
	display: none;
  }
}

// Navigation body section
.navigation-body-section {
  width: 100%;
  display: inline-flex;
  padding: $navigation-body-section-padding;
  @include flexColumn();

  @media (min-width: $landscape-width) {
	width: auto;
	display: inline-block;
	padding: 16px 0;
  }

  & + & {
	padding-top: 0;

	@media (min-width: $landscape-width) {
	  padding-top: $navigation-body-section-padding;
	}
  }
}