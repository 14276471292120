
/* UTILITIES
 ================================*/
.align-to-right{
	margin-left: auto !important;
}
.align-to-left{
	margin-right: auto !important;
}
.margin-top{
	margin-top: 1rem !important;
	
	@media (min-width: $landscape-width){
		margin-top: 0 !important;
	}
}
.hide-on-portrait{	
	@media (max-width: $landscape-width - 1){
		display: none !important;
	}
}
.hide-on-landscape{	
	@media (min-width: $landscape-width){
		display: none !important;
	}
}